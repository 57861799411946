import React, {useEffect, useState} from 'react';
import "./style.scss";
import Draggable from 'react-draggable';
import Functions from '../../../functions';
import i18n from '../../../i18n';
import {useTranslation} from "react-i18next";

export default function MenuSettings() {
    const {t} = useTranslation();

    const [toggleLang, setToggleLang] = useState("");
    const clickToggleLang = (lang) => {
        i18n.changeLanguage(lang);
        setToggleLang(lang);
    };

    const [toggleTab, setToggleTab] = useState('languages');

    const [contributers, setContributers] = useState([]);

    useEffect(() => {
        document.getElementById('menu-settings').classList.add('hidden');
        setTimeout(() => {
            setToggleLang(localStorage.getItem("i18nextLng"));
        }, 100)
        fetch("/assets/storages/contributers.json")
            .then(response => response.json())
            .then(d => setContributers(d))
    }, []);

    return (
        <Draggable bounds='container' handle='header'>
            <window id='menu-settings'>
                <header>
                    <span><menuicon/>
                        {t("ui:NavBar.options")}</span>
                    <closebutton onClick={() => Functions.menuShowHide("menu-settings")}/>
                </header>
                <columns>
                    <category>
                        <button
                            className={toggleTab === 'languages' ? "active" : ""}
                            onClick={() => setToggleTab('languages')}
                        >{t("ui:OptionsMenu.Categories.languages")}</button>
                        <button
                            className={toggleTab === 'mapping' ? "active" : ""}
                            onClick={() => setToggleTab('mapping')}
                        >{t("ui:OptionsMenu.Categories.mapping")}</button>
                        <button
                            className={toggleTab === 'reset' ? "active" : ""}
                            onClick={() => setToggleTab('reset')}
                        >{t("ui:OptionsMenu.Categories.reset")}</button>
                        <button
                            className={toggleTab === 'about' ? "active" : ""}
                            onClick={() => setToggleTab('about')}
                        >{t("ui:OptionsMenu.Categories.about")}</button>
                    </category>
                    <>
                        <items id="languages" className={toggleTab === 'languages' ? "active" : ""}>
                            <button onClick={() => clickToggleLang("en-US")}>
                                <span className={toggleLang === 'en-US' ? "active" : ""}/>
                                <div>{t("ui:OptionsMenu.Items.languages.english")} / English</div>
                            </button>
                        </items>
                        <items id="mapping" className={toggleTab === 'mapping' ? "active" : ""}>
                            <button onClick={() => {
                                window.open("/mapping")
                            }}>{t("ui:OptionsMenu.Items.mapping.start")}</button>
                        </items>
                        <items id="reset" className={toggleTab === 'reset' ? "active" : ""}>
                            <button onClick={() => {
                                window.localStorage.removeItem("i18nextLng");
                                window.location.reload()
                            }}>{t("ui:OptionsMenu.Items.reset.lang")}</button>
                            <button onClick={() => {
                                window.localStorage.removeItem("settings");
                                window.location.reload()
                            }}>{t("ui:OptionsMenu.Items.reset.visibility")}</button>
                            <button onClick={() => {
                                window.localStorage.removeItem("checked");
                                window.location.reload()
                            }}>{t("ui:OptionsMenu.Items.reset.checked")}</button>
                            <button onClick={() => {
                                window.localStorage.clear();
                                window.location.reload()
                            }}>{t("ui:OptionsMenu.Items.reset.everything")}</button>
                        </items>
                        <items id="about" className={toggleTab === 'about' ? "active" : ""}>
                            <p>
                                {t("ui:page_title")}
                                <br/><br/>
                                I picked the project up as a free time activity. Fixing a lot of code and other security
                                issues in the code.
                                Trying to maintain the map as good as possible!
                                <br/><br/>
                                {t("ui:OptionsMenu.Items.about.contributers")}:<br/>
                                {contributers.map((x =>
                                        <>• <name onClick={() => {
                                            window.open(x.link)
                                        }}>{x.name}</name> - {x.info}<br/></>
                                ))}
                                <br/><br/>
                                {t("ui:OptionsMenu.Items.about.segaCopyright")}
                            </p>
                        </items>
                    </>
                </columns>
            </window>
        </Draggable>
    );
};